import { gql } from "@apollo/client";
import { useEffect, useState, createContext, useMemo } from "react";
import { useErrorHandler } from "react-error-boundary";
import {
  Navigate,
  useNavigate,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import {
  useRecipeCookMutation,
  useResourceEndMutation,
  useResourceStartMutation,
  useCheckIfXapiCompleteLazyQuery,
  useResourceViewV2Query,
  useResourceViewV2LazyQuery,
  useResourceAccessibilityQuery,
  useResourceAccessibilityLazyQuery,
  useScheduleBlockStatusTrainingInfoQuery,
} from "../../../generated/graphql";
import { useTimeElapsed } from "../../../helpers/Timer";
import {
  FreedomWallItem,
  LoadingCentered,
} from "../../../molecules/components";
import {
  CourseBlockOpenModals,
  ResourceNavbar,
  ViewResourceBuriBooks,
  ViewResourceFileSubmissionV2,
  ViewResourceHtml,
  ViewResourceMarkdown,
  Modal,
  CantNextXapi,
  ViewResourceFreedomWall,
  ResourceNotAccessible,
  CantNextVideo,
} from "../../../organisms/components";
import { ResourceCompleteModal } from "../../../organisms/components/ResourceCompleteModal";
import { ViewResourceEmbed } from "../../../organisms/components/ViewResourceEmbed";
import { ViewResourceEPUB } from "../../../organisms/components/ViewResourceEPUB";
import { ViewResourceLink } from "../../../organisms/components/ViewResourceLink";
import { ViewResourcePdf } from "../../../organisms/components/ViewResourcePdf";
import { ViewResourceVideo } from "../../../organisms/components/ViewResourceVideo.tsx";
import { ViewResourceLayout } from "../../../templates/components/ViewResourceLayout";
import { ViewResourceXApi } from "../../../organisms/components";
import { CourseBlock } from "../CourseViewer";
import { AssessmentViewerV2 } from "../AssessmentViewerV2";
import { CamelCasedType } from "../../../helpers/components";
import { Button, Icon, Image } from "../../../atoms/components";
import indicatorTrophy from "../../../assets/trophyindicator.png"; // Training Info
import { GbfSlide } from "../GbfSlide";
import { Casticulate } from "../Casticulate";
import { useOnlineStatus } from "../../../helpers/OnlineStatus/OnlineStatus";

export type CourseBlockV2 = CamelCasedType<CourseBlock>;
export const LastPageContext = createContext<{
  handleIsSlideLastPage: (isLastPage: boolean) => void;
  handleTrainingIndicatorsEarned: () => void;
}>({
  handleIsSlideLastPage: () => {},
  handleTrainingIndicatorsEarned: () => {},
});

export interface ViewResourceV2Props {
  resourceUuid?: string;
}

export interface ViewResourceV2TypeSpecificPropsBase {
  onResourceStart?: () => any;
  onResourceEnd?: () => any;
}

export const MUTATION_RESOURCE_START = gql`
  mutation ResourceStart(
    $resourceUuid: ID!
    $courseBlockUuid: String
    $courseScheduleUuid: String
  ) {
    resource_start(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
      course_schedule_uuid: $courseScheduleUuid
    )
  }
`;

export const MUTATION_RESOURCE_END = gql`
  mutation ResourceEnd(
    $resourceUuid: ID!
    $courseBlockUuid: String
    $courseScheduleUuid: String
  ) {
    resource_end(
      resource_uuid: $resourceUuid
      course_block_uuid: $courseBlockUuid
      course_schedule_uuid: $courseScheduleUuid
    ) {
      next_course_blocks {
        uuid
        title
        resource {
          uuid
        }
        coverUri: cover_uri
      }
    }
  }
`;

export const MUTATION_RECIPE_COOK = gql`
  mutation RecipeCook($ingredient: RecipeIngredients) {
    recipe_cook(ingredient: $ingredient)
  }
`;
const MUTATION_RECIPE_COOK_MULTIPLE = gql`
  mutation RecipeCookMultiple($ingredients: [RecipeIngredients]) {
    recipe_cook_multiple(ingredients: $ingredients)
  }
`;

export const CHECK_XAPI_COMPLETION_QUERY = gql`
  query CheckIfXapiComplete($resourceDetailsInput: ResourceDetailsInput!) {
    resourceViewScreenV2: resource_view_screen_v2(
      resource_details_input: $resourceDetailsInput
    ) {
      courseBlock: course_block {
        hasUserComplete: has_user_completed
      }
    }
  }
`;
function useViewResourceHooks() {
  const [isResourceDone, setIsResourceDone] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const resourceUuid = searchParams.get("resource_uuid");
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const courseScheduleUuid = searchParams.get("course_schedule_uuid");
  const courseUuid = searchParams.get("course_uuid");
  const isSlideLastPage = searchParams.get("last_page");
  const isInCourse: boolean = !!courseBlockUuid;

  // For checking if online
  const { isOnline } = useOnlineStatus();

  // When online status changes, make sure initial post is only done once
  const [initialPostDone, setInitialPostDone] = useState(false);

  // For navigation
  const navigate = useNavigate();

  // Resource complete modal
  const [resourceCompleteModalOpen, setResourceCompleteModalOpen] =
    useState(false);

  // Training Indicators Earned modal  // Training Info
  const [indicatorsEarnedModalOpen, setIndicatorsEarnedModalOpen] = // Training Info
    useState(false); // Training Info

  // Course block resource modal
  const [
    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
  ] = useState(false);
  const [nextCourseBlockUuid, setNextCourseBlockUuid] = useState<string>();
  const [nextResourceUuid, setNextResourceUuid] = useState<string>();
  const [
    getResourceView,
    {
      loading: resourceViewScreenLoading,
      data: resourceViewScreenData,
      error: resourceViewScreenError,
    },
  ] = useResourceViewV2LazyQuery();

  // change to Lazy query so it can be refetched when isOnline state changes
  const [
    getResourceAccessibility,
    { data: resourceAccessibilityData, loading: resourceAccessibilityLoading },
  ] = useResourceAccessibilityLazyQuery();

  useEffect(() => {
    if (resourceUuid) {
      getResourceAccessibility({
        variables: {
          resourceDetailsInput: {
            resourceUuid: resourceUuid || "",
            courseBlockUuid: courseBlockUuid || "",
            scheduleUuid: courseScheduleUuid || null,
          },
        },
        fetchPolicy: isOnline ? "no-cache" : "cache-first",
        onCompleted: (data) => {
          if (
            data.resourceViewScreenV2?.courseBlock === null ||
            data.resourceViewScreenV2?.courseBlock?.isAccessible ||
            data.resourceViewScreenV2?.courseBlock?.userstatus?.status ===
              "in_progress"
          ) {
            // getResourceView();

            getResourceView({
              variables: {
                resourceDetailsInput: {
                  resourceUuid: resourceUuid || "",
                  courseBlockUuid: courseBlockUuid || "",
                  scheduleUuid: courseScheduleUuid || null,
                },
              },
              fetchPolicy: isOnline
                ? data.resourceViewScreenV2.courseBlock?.resource?.type ===
                  "assessment"
                  ? "network-only"
                  : "cache-and-network"
                : "cache-first",
            });
          }
        },
      });
    }
  }, [isOnline, resourceUuid, courseBlockUuid]);

  // Training Info from Course Block Status  // Training Info
  const {
    // Training Info
    data: trainingInfoData, // Training Info
    loading: trainingInfoLoading, // Training Info
  } = useScheduleBlockStatusTrainingInfoQuery({
    // Training Info
    variables: {
      // Training Info
      courseUuid: courseUuid || "",
      courseBlockUuid: courseBlockUuid || "", // Training Info
      scheduleUuid: courseScheduleUuid || "",
    }, // Training Info
  }); // Training Info

  // Resource Start mutate
  const [
    resourceStartMutateFunction,
    {
      loading: resourceStartLoading,
      data: resourceStartData,
      error: resourceStartError,
    },
  ] = useResourceStartMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid: courseBlockUuid,
      courseScheduleUuid: courseScheduleUuid,
    },
  });

  const [
    resourceEndMutateFunction,
    {
      loading: resourceEndMutateLoading,
      data: resourceEndMutateData,
      error: resourceEndMutateError,
    },
  ] = useResourceEndMutation({
    variables: {
      resourceUuid: resourceUuid as string,
      courseBlockUuid: courseBlockUuid,
      courseScheduleUuid: courseScheduleUuid,
    },
  });

  const [checkXapiCompletion, { data: checkXapiCompletionData }] =
    useCheckIfXapiCompleteLazyQuery();

  const [recipeCookMutateFunction] = useRecipeCookMutation();

  const { reset, getTimeElapsedMs } = useTimeElapsed();

  useErrorHandler(
    resourceViewScreenError || resourceStartError || resourceEndMutateError
  );

  // Time Spent Data
  const [watchDuration, setWatchDuration] = useState<number | null>(null);
  // User Activity Variables
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  // On End
  const [videoFinished, setVideoFinished] = useState<boolean>(false);

  // Xapi Complete Modal
  const [isXapiCompleteModalOpen, setIsXapiCompleteModalOpen] =
    useState<boolean>(false);

  const [isVideoFinishedModalOpen, setIsVideoFinishedModalOpen] =
    useState<boolean>(false);

  return {
    isResourceDone,
    setIsResourceDone,

    resourceUuid,
    courseUuid,
    courseBlockUuid,
    courseScheduleUuid,
    isInCourse,

    navigate,

    resourceCompleteModalOpen,
    setResourceCompleteModalOpen,

    indicatorsEarnedModalOpen, // Training Info
    setIndicatorsEarnedModalOpen, // Training Info

    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
    nextCourseBlockUuid,
    setNextCourseBlockUuid,
    nextResourceUuid,
    setNextResourceUuid,

    resourceViewScreenLoading,
    resourceViewScreenData,

    resourceAccessibilityData,
    resourceAccessibilityLoading,

    resourceStartMutateFunction,
    resourceEndMutateFunction,
    resourceEndMutateLoading,
    resourceEndMutateData,

    recipeCookMutateFunction,

    isOnline,

    getTimeElapsedMs,
    reset,

    watchDuration,
    setWatchDuration,

    intervalId,
    setIntervalId,

    videoFinished,
    setVideoFinished,

    checkXapiCompletion,

    isXapiCompleteModalOpen,
    setIsXapiCompleteModalOpen,

    isVideoFinishedModalOpen,
    setIsVideoFinishedModalOpen,

    trainingInfoData, // Training Info
    trainingInfoLoading, // Training Info
    initialPostDone,
    setInitialPostDone,
  };
}

const nonAutoCompleteTypes = [
  "video",
  "buribook-with-activity",
  "burislides",
  "assessment",
  "course",
  "xapi",
  "casticulate",
];

export function ViewResourceV2({}: ViewResourceV2Props) {
  const {
    isResourceDone,
    setIsResourceDone,

    resourceUuid,
    courseUuid,
    courseBlockUuid,
    courseScheduleUuid,
    isInCourse,

    navigate,

    resourceCompleteModalOpen,
    setResourceCompleteModalOpen,

    indicatorsEarnedModalOpen, // Training Info
    setIndicatorsEarnedModalOpen, // Training Info

    courseBlockResourceModalsAllowOpen,
    setCourseBlockResourceModalsAllowOpen,
    nextCourseBlockUuid,
    setNextCourseBlockUuid,
    nextResourceUuid,
    setNextResourceUuid,

    resourceViewScreenLoading,
    resourceViewScreenData,

    resourceAccessibilityData,
    resourceAccessibilityLoading,

    resourceStartMutateFunction,
    resourceEndMutateFunction,
    resourceEndMutateLoading,
    resourceEndMutateData,

    recipeCookMutateFunction,

    isOnline,
    initialPostDone,
    setInitialPostDone,

    getTimeElapsedMs,
    reset,

    watchDuration,
    setWatchDuration,

    intervalId,
    setIntervalId,

    videoFinished,
    setVideoFinished,

    checkXapiCompletion,

    isXapiCompleteModalOpen,
    setIsXapiCompleteModalOpen,

    isVideoFinishedModalOpen,
    setIsVideoFinishedModalOpen,

    trainingInfoData, // Training Info
    trainingInfoLoading, // Training Info
  } = useViewResourceHooks();

  const resourceViewV2 = resourceViewScreenData?.resourceViewScreenV2;
  const nextResource = resourceViewV2?.courseBlock?.nextBlocksJoined;
  const { type, name, content, subtype, uuid } = resourceViewV2?.resource || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const [isSlideLastPage, setIsSlideLastPage] = useState<boolean>(false);
  const scheduleUuid = searchParams.get("course_schedule_uuid");
  const slideDoesntHaveLastPageLogic = useMemo(
    () =>
      type !== "casticulate" &&
      type !== "buribook-with-activity" &&
      type !== "burislides",
    [type]
  );

  function handleIsSlideLastPage(isLastPage: boolean) {
    setIsSlideLastPage(isLastPage);
  }

  useEffect(() => {
    if (isSlideLastPage && isOnline) {
      resourceEndMutateFunction();
    }
  }, [isSlideLastPage]);

  const trainingInfo =
    trainingInfoData?.courseViewScreen?.scheduleBlockStatus?.trainingInfo; // Training Info
  const isTrainingInfo = trainingInfo !== null; // Training Info

  function handleTrainingIndicatorsEarned() {
    // Training Info
    if (isTrainingInfo) {
      // Training Info
      setIndicatorsEarnedModalOpen(true); // Training Info
    } else {
      // Training Info
      setResourceCompleteModalOpen(true); // Training Info
    } // Training Info
  } // Training Info

  const longUnitName =
    trainingInfo?.domains?.length &&
    trainingInfo?.domains[0]?.indicators &&
    trainingInfo?.domains[0]?.indicators?.length &&
    trainingInfo?.domains[0].indicators[0]?.displayUnitName
      ? trainingInfo.domains[0].indicators[0]?.displayUnitName
      : "point(s)";

  // Behaviour for next button
  const onNext = () => {
    if (type === "xapi") {
      checkXapiCompletion({
        variables: {
          resourceDetailsInput: {
            resourceUuid: resourceUuid || "",
            courseBlockUuid: courseBlockUuid,
            scheduleUuid: scheduleUuid,
          },
        },
        onCompleted: (data) => {
          if (data.resourceViewScreenV2?.courseBlock?.hasUserComplete) {
            handleTrainingIndicatorsEarned();
          } else {
            setIsXapiCompleteModalOpen(true);
          }
        },
        fetchPolicy: isOnline ? "network-only" : "cache-first",
      });
    } else if (type === "video") {
      if (
        resourceViewV2?.courseBlock?.userstatus?.isComplete ||
        videoFinished
      ) {
        resourceEndMutateFunction();
        handleTrainingIndicatorsEarned();
      } else {
        setIsVideoFinishedModalOpen(true);
      }
    } else {
      if (isResourceDone) {
        handleTrainingIndicatorsEarned();
      }
      setIsResourceDone(true);
    }
  };

  const finishOnStart = () => {
    resourceEndMutateFunction();

    if (type === "video") recordAttempt();
    else {
      isInCourse
        ? cookRecipes.cookAsCompletedInsideCourse()
        : cookRecipes.cookAsCompletedOutsideCourse();
    }
  };

  // Behavior when playing Video
  const startTimer = () => {
    let watchInterval = setInterval(() => {
      setWatchDuration((watchDuration) =>
        watchDuration ? watchDuration + 1 : 1
      );
    }, 1000);
    setIntervalId(watchInterval);

    // Just incase need niyo po ito
    setVideoFinished(false);
  };

  const stopTimer = () => {
    clearInterval(intervalId);
  };

  const onEnd = () => {
    setVideoFinished(true);
    setIsResourceDone(true);
  };

  // Behaviour for close button
  const recordAttempt = () => {
    const duration = getTimeElapsedMs();
    reset();

    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: isInCourse ? "resource_in_course" : "resource",
          body: {
            timestamp: new Date().toISOString(),
            object: {
              resource_id: resourceUuid,
              block_id: courseBlockUuid,
              course_id: courseUuid,
            },
            result: {
              seconds: watchDuration || duration / 1000,
            },
            verb: "attempted",
            context: {
              block_id: courseBlockUuid,
              course_id: courseUuid,
              registration: scheduleUuid || resourceUuid,
            },
          },
        },
      },
    });
  };

  // Invoke only on resource done
  useEffect(() => {
    if (isResourceDone) {
      // All types inside this if statement will trigger mutations. All other types has already done this using finishOnStart
      if (nonAutoCompleteTypes.includes(type as string)) {
        if (isOnline) {
          resourceEndMutateFunction();

          if (type === "video") recordAttempt();
          else {
            isInCourse
              ? cookRecipes.cookAsCompletedInsideCourse()
              : cookRecipes.cookAsCompletedOutsideCourse();
          }
        }
      }

      if (trainingInfo) {
        // Training Info
        handleTrainingIndicatorsEarned(); // Training Info
      } else {
        // Training Info
        setResourceCompleteModalOpen(true); // Training Info
      } // Training Info

      setIsResourceDone(false);
    }
  }, [isInCourse, isResourceDone, isOnline]);

  function cookAsCompletedOutsideCourse() {
    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: "resource",
          body: {
            timestamp: new Date().toISOString(),
            object: {
              resource_id: resourceUuid,
            },
            verb: "completed",
            context: {
              resource_id: resourceUuid,
              registration: scheduleUuid || resourceUuid,
            },
          },
        },
      },
    });
  }

  function cookAttemptOutsideCourse() {
    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: "resource",
          body: {
            timestamp: new Date().toISOString(),
            object: { resource_id: resourceUuid },
            verb: "attempted",
            context: {
              resource_id: resourceUuid,
              registration: resourceUuid,
            },
          },
        },
      },
    });
  }

  function cookAsCompletedInsideCourse() {
    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: "resource_in_course",
          body: {
            timestamp: new Date().toISOString(),
            object: {
              resource_id: resourceUuid,
              block_id: courseBlockUuid,
              course_id: courseUuid,
            },
            verb: "completed",
            context: {
              block_id: courseBlockUuid,
              course_id: courseUuid,
              registration: scheduleUuid,
            },
          },
        },
      },
    });
  }

  function cookAttemptInsideCourse() {
    recipeCookMutateFunction({
      variables: {
        ingredient: {
          recipe_name: "resource_in_course",
          body: {
            timestamp: new Date().toISOString(),
            object: {
              resource_id: resourceUuid,
              block_id: courseBlockUuid,
              course_id: courseUuid,
            },
            verb: "attempted",
            context: {
              block_id: courseBlockUuid,
              course_id: courseUuid,
              registration: scheduleUuid,
            },
          },
        },
      },
    });
  }

  const cookRecipes = {
    cookAsCompletedInsideCourse: cookAsCompletedInsideCourse,
    cookAttemptInsideCourse: cookAttemptInsideCourse,
    cookAsCompletedOutsideCourse: cookAsCompletedOutsideCourse,
    cookAttemptOutsideCourse: cookAttemptOutsideCourse,
  };

  // Invoke at start
  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      isOnline &&
      !initialPostDone &&
      resourceViewScreenData &&
      !resourceViewScreenLoading
    ) {
      setInitialPostDone(true);
      resourceStartMutateFunction();
      if (content?.__typename !== "ResourceContentCourse" && !isInCourse) {
        cookRecipes.cookAttemptOutsideCourse();
      } else if (isInCourse) {
        //cook statement here for resource inside course
        cookRecipes.cookAttemptInsideCourse();
      }
    }
  }, [isOnline, initialPostDone, resourceViewScreenData]);

  if (!resourceAccessibilityData || resourceAccessibilityLoading) {
    return <LoadingCentered></LoadingCentered>;
  }

  if (
    resourceAccessibilityData?.resourceViewScreenV2?.courseBlock &&
    !resourceAccessibilityData?.resourceViewScreenV2?.courseBlock
      ?.isAccessible &&
    resourceAccessibilityData?.resourceViewScreenV2.courseBlock.userstatus
      ?.status !== "in_progress"
  ) {
    return <ResourceNotAccessible />;
  }

  return (
    // Need pa ba tong View Resource Layout?
    <LastPageContext.Provider
      value={{
        handleIsSlideLastPage: handleIsSlideLastPage,
        handleTrainingIndicatorsEarned: handleTrainingIndicatorsEarned,
      }}
    >
      <ViewResourceLayout
        key={uuid}
        navbar={
          // May sariling NavBar na kasi si assessment
          type !== "assessment" ||
          subtype === "file-upload" ||
          subtype === "freedom-wall" ? (
            <ResourceNavbar
              resourceName={name || ""}
              isNextEnabled={
                isInCourse && (isSlideLastPage || slideDoesntHaveLastPageLogic)
              }
              onNext={onNext}
              onClose={recordAttempt}
              courseBlockUuid={courseBlockUuid as string}
            />
          ) : (
            <></>
          )
        }
      >
        {courseBlockResourceModalsAllowOpen ? (
          <CourseBlockOpenModals
            allowOpenModal={courseBlockResourceModalsAllowOpen}
            onModalClose={() => setCourseBlockResourceModalsAllowOpen(false)}
            courseBlockUuid={nextCourseBlockUuid}
            courseUuid={courseUuid as string}
            scheduleUuid={scheduleUuid as string}
            resourceUuid={nextResourceUuid}
          ></CourseBlockOpenModals>
        ) : (
          <></>
        )}
        {/* Training Indicators Earned Modal */}
        <Modal
          className={"w-5/6 md:w-2/5 max-h-5/6 overflow-auto"}
          isOpen={indicatorsEarnedModalOpen}
          onRequestClose={() => setIndicatorsEarnedModalOpen(false)}
        >
          <div className="p-2">
            <div className="w-full">
              <Image
                src={indicatorTrophy}
                height={300}
                alt="Indicator Trophy"
                className="w-full mx-auto"
              />
            </div>
            <div className="my-6 text-2xl font-bold text-center text-gray-700">
              <p>Well done!</p>
              <p className="w-full break-words hyphens-auto">
                You earned{" "}
                <span className="mr-2 text-secondary-500 ">
                  {trainingInfo?.totalPoints} {longUnitName}
                </span>
                for the following Indicators:
              </p>
            </div>

            <div className="flex flex-col w-full ">
              {/* Loop trainingInfo domains */}
              {trainingInfo?.domains?.map((domain) => (
                <div
                  className="items-center justify-between mb-6 space-x-4 "
                  key={domain?.uuid}
                >
                  <div className="flex justify-between w-full space-x-4 text-lg font-bold text-gray-700 align-middle">
                    <p className="w-full">{domain?.name}</p>
                    <p className="w-full font-bold text-right truncate text-secondary-500">
                      {domain?.totalPoints}{" "}
                      {domain?.indicators &&
                      domain?.indicators.length &&
                      domain?.indicators[0]?.displayUnitName
                        ? domain?.indicators[0]?.displayUnitName
                        : "pt(s)"}
                    </p>
                  </div>

                  {/* SUBDOMAINS */}
                  <div className="text-gray-500">
                    {domain?.children?.map((subdomain) => (
                      <ul
                        key={subdomain?.uuid}
                        className="w-full my-3 list-disc "
                      >
                        <li className="w-full ">
                          <div className="flex justify-between w-full space-x-4 text-right">
                            <p className="w-full font-bold text-left">
                              {subdomain?.name}
                            </p>
                            <p className="w-full font-bold text-right truncate">
                              {subdomain?.totalPoints}{" "}
                              {subdomain?.indicators &&
                              subdomain?.indicators.length &&
                              subdomain?.indicators[0]?.displayUnitName
                                ? subdomain?.indicators[0]?.displayUnitName
                                : "pt(s)"}
                            </p>
                          </div>
                          {/* Indicator */}
                          {subdomain?.indicators?.map((indicator) => (
                            <div key={indicator?.uuid} className="">
                              {indicator?.name}
                            </div>
                          ))}
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="w-1/2 mx-auto"
            color="secondary"
            onClick={() => {
              setIndicatorsEarnedModalOpen(false);
              setResourceCompleteModalOpen(true);
            }}
          >
            Continue
          </Button>
        </Modal>
        <ResourceCompleteModal
          nextBlocks={
            !resourceEndMutateLoading
              ? (resourceViewScreenData?.resourceViewScreenV2?.courseBlock
                  ?.nextBlocksJoined as CourseBlock[])
              : []
          }
          isOpen={resourceCompleteModalOpen}
          onRequestClose={() => setResourceCompleteModalOpen(false)}
          onBackToMainPage={() => {
            setResourceCompleteModalOpen(false);

            if (!courseUuid) {
              if (searchParams.get("search_value")) {
                navigate(
                  `/screen/search?search_value=${searchParams.get(
                    "search_value"
                  )}`
                );
              } else {
                navigate(`/screen/${parentScreenName}`);
              }
            } else {
              navigate(
                `/courseviewer?course_uuid=${searchParams.get(
                  "course_uuid"
                )}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${
                  parentScreenName === "search"
                    ? `&search_value=${searchParams.get("search_value")}`
                    : ""
                }`
              );
            }
          }}
          onBlockClick={(block) => {
            setNextCourseBlockUuid(block.uuid || "");
            setNextResourceUuid(block.resource?.id || "");
            setCourseBlockResourceModalsAllowOpen(true);

            setResourceCompleteModalOpen(false);
          }}
        />
        <Modal
          isOpen={isXapiCompleteModalOpen}
          onRequestClose={() => setIsXapiCompleteModalOpen(false)}
        >
          <CantNextXapi closeModal={() => setIsXapiCompleteModalOpen(false)} />
        </Modal>
        <Modal
          isOpen={isVideoFinishedModalOpen}
          onRequestClose={() => setIsVideoFinishedModalOpen(false)}
        >
          <CantNextVideo
            closeModal={() => setIsVideoFinishedModalOpen(false)}
          />
        </Modal>
        {(resourceViewScreenLoading && !resourceViewScreenData) ||
        (!isOnline && type === "assessment") ? ( // don't show cached assessment if offline
          <LoadingCentered />
        ) : (
          <ResourceTypeSwitch
            key={resourceUuid}
            resourceType={type || ""}
            resourceSubtype={subtype || ""}
            resourceUuid={resourceUuid || ""}
            content={content}
            uuid={uuid as string}
            blockUuid={courseBlockUuid as string}
            scheduleUuid={courseScheduleUuid as string}
            onEndResource={() => setIsResourceDone(true)}
            parentScreenName={parentScreenName as string}
            startTimer={startTimer}
            stopTimer={stopTimer}
            finishOnStart={finishOnStart}
            onEnd={onEnd}
            isOnline={!!isOnline}
            courseBlock={
              resourceViewScreenData?.resourceViewScreenV2?.courseBlock || {}
            }
            referenceId={
              resourceViewScreenData?.resourceViewScreenV2?.courseBlock
                ?.scheduleReferenceInfo?.referenceId || ""
            }
          ></ResourceTypeSwitch>
        )}
      </ViewResourceLayout>
    </LastPageContext.Provider>
  );
}

const ResourceTypeSwitch = ({
  resourceUuid,
  resourceType,
  resourceSubtype,
  content,
  uuid,
  blockUuid,
  scheduleUuid,
  parentScreenName,
  onEndResource,
  startTimer,
  stopTimer,
  onEnd,
  finishOnStart,
  courseBlock,
  isOnline,
  referenceId,
}: {
  resourceUuid: string;
  resourceType: string;
  resourceSubtype?: string;
  content?: any;
  uuid?: string;
  blockUuid?: string;
  scheduleUuid?: string;
  onEndResource: () => any;
  parentScreenName?: string;
  startTimer?: () => any;
  stopTimer?: () => any;
  onEnd?: () => any;
  finishOnStart?: () => any;
  courseBlock?: CourseBlockV2;
  isOnline: boolean | undefined;
  referenceId: string;
}) => {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get("course_uuid");
  const location = useLocation();

  // To Auto Finish, Put here to ensure data is received
  useEffect(() => {
    if (isOnline) {
      if (
        resourceType !== "" &&
        !nonAutoCompleteTypes.includes(resourceType) &&
        finishOnStart
      ) {
        console.log("FINISHED ON START!");
        finishOnStart();
      }
    }
  }, [resourceType, isOnline]);

  if (resourceType === "video") {
    return (
      <ViewResourceVideo
        key={resourceUuid}
        file={content}
        onPlay={startTimer}
        onPause={stopTimer}
        onEnd={onEnd}
        className="w-full h-full max-h-[88vh]"
      ></ViewResourceVideo>
    );
  } else if (resourceType === "pdf") {
    return (
      <ViewResourcePdf key={resourceUuid} uri={content.uri}></ViewResourcePdf>
    );
  } else if (resourceType === "html") {
    return <ViewResourceHtml key={resourceUuid} src={content.uri} />;
  } else if (resourceType === "embed" || resourceType === "youtube") {
    return (
      <div className="h-screen">
        <ViewResourceEmbed key={resourceUuid} src={content.uri} />
      </div>
    );
  } else if (
    resourceType === "buribook-with-activity" ||
    resourceType === "burislides"
  ) {
    if (resourceSubtype === "gbfslide" || true)
      return (
        <GbfSlide
          resourceDetailsInput={{
            resourceUuid: resourceUuid || "",
            courseBlockUuid: blockUuid,
            scheduleUuid: scheduleUuid,
          }}
          referenceId={referenceId}
          key={resourceUuid}
          onLastPage={() => onEndResource()}
        />
      );
    else
      return (
        <ViewResourceBuriBooks
          resourceUuid={resourceUuid}
          onLastPage={() => onEndResource()}
        />
      );
  } else if (resourceType === "epub") {
    return <ViewResourceEPUB key={resourceUuid} src={content.uri} />;
  } else if (resourceType === "link") {
    return <ViewResourceLink key={resourceUuid} link={content.uri} />;
  } else if (resourceType === "text") {
    return <ViewResourceMarkdown key={resourceUuid} text={content.text} />;
  } else if (resourceType === "xapi") {
    return <ViewResourceXApi key={resourceUuid} uri={content.uri} />;
  } else if (resourceType === "assessment") {
    if (resourceSubtype === "file-upload") {
      return (
        <ViewResourceFileSubmissionV2
          // timeslotUuid={content.examDetails.examTimeslotUuid}
          key={resourceUuid}
          content={content}
          scheduleReferenceId={
            courseBlock?.scheduleReferenceInfo?.referenceId || ""
          }
        />
      );
    }
    if (resourceSubtype === "freedom-wall") {
      return <ViewResourceFreedomWall key={resourceUuid} content={content} />;
    } else {
      return (
        // Work around kasi predefined yung shape ni ResourceAssessment sa mismong gql na code. di siya based sa query so di possible yung camel casing
        <AssessmentViewerV2
          key={resourceUuid}
          content={content}
          courseBlock={courseBlock}
        />
      );
    }
  } else if (resourceType === "course") {
    return (
      <Navigate
        to={`/courseviewer?course_uuid=${
          content.courseDetails.courseUuid
          // to={`/batchviewer?schedule_uuid=${
          // scheduleUuid
        }&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${
          parentScreenName === "search"
            ? `&search_value=${searchParams.get("search_value")}`
            : ""
        }`}
        replace={true}
      />
    );
  } else if (resourceType === "casticulate") {
    return <Casticulate casticulateUuid={content.casticulateUuid} />;
  } else return <></>;
};
